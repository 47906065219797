import React from 'react';
import { arrayOf, shape, number, bool, object, func } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { isScrollingDisabled, manageDisableScrolling } from '../../ducks/UI.duck';
import { getListingsById } from '../../ducks/marketplaceData.duck';
import { withViewport } from '../../util/contextHelpers';
import config from '../../config';
import {
  Page,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  // sections
  SectionHero,
  SectionListings,
  SectionExperiences,
  SectionJoin,
  SectionRecentlyViewedListings,
  SectionWatchlistListings,
  SectionListingVideos,
} from '../../components';
import { TopbarContainer } from '../../containers';
import classNames from 'classnames';

import facebookImage from '../../assets/attractrFacebook-1200x630.jpg';
import twitterImage from '../../assets/attractrTwitter-600x314.jpg';
import css from './LandingPage.module.css';

export const LandingPageComponent = props => {
  const {
    intl,
    scrollingDisabled,
    viewport,
    listings,
    experiences,
    currentUser,
    queryListingsInProgress,
    queryListingsError,
    queryExperiencesInProgress,
    queryExperiencesError,
    watchlistListings,
    queryWatchlistListingsInProgress,
    queryWatchlistListingsError,
    listingVideos,
    queryListingVideosInProgress,
    queryListingVideosError,
    recentlyViewedListings,
    queryRecentlyViewedListingInProgress,
    queryRecentlyViewedListingsError,
  } = props;

  // Schema for search engines (helps them to understand what this page is about)
  // http://schema.org
  // We are using JSON-LD format
  const siteTitle = config.siteTitle;
  const schemaTitle = intl.formatMessage({ id: 'LandingPage.schemaTitle' }, { siteTitle });
  const schemaDescription = intl.formatMessage({ id: 'LandingPage.schemaDescription' });
  const schemaImage = `${config.canonicalRootURL}${facebookImage}`;

  return (
    <Page
      className={css.root}
      scrollingDisabled={scrollingDisabled}
      currentPage="LandingPage"
      contentType="website"
      description={schemaDescription}
      title={schemaTitle}
      facebookImages={[{ url: facebookImage, width: 1200, height: 630 }]}
      twitterImages={[
        { url: `${config.canonicalRootURL}${twitterImage}`, width: 600, height: 314 },
      ]}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: schemaDescription,
        name: schemaTitle,
        image: [schemaImage],
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
          <div className={css.heroContainer}>
            <SectionHero viewport={viewport} listings={listings} />
          </div>
          <ul className={css.sections}>
            <li className={css.section}>
              <div className={classNames(css.sectionContentFirstChild, css.sectionSlider)}>
                <SectionListings
                  intl={intl}
                  listings={listings}
                  queryListingsInProgress={queryListingsInProgress}
                  queryListingsError={queryListingsError}
                />
              </div>
            </li>
            <li className={css.section}>
              <div className={classNames(css.sectionContent, css.sectionSlider)}>
                <SectionExperiences
                  intl={intl}
                  experiences={experiences}
                  queryExperiencesInProgress={queryExperiencesInProgress}
                  queryExperiencesError={queryExperiencesError}
                />
              </div>
            </li>
            <li className={css.section}>
              <div className={classNames(css.sectionContent, css.sectionSlider)}>
                <SectionWatchlistListings
                  intl={intl}
                  listings={watchlistListings}
                  queryWatchlistListingsInProgress={queryWatchlistListingsInProgress}
                  queryWatchlistListingsError={queryWatchlistListingsError}
                />
              </div>
            </li>
            <li className={css.section}>
              <div className={css.sectionContent}>
                <SectionJoin
                  currentUser={currentUser}
                  listings={watchlistListings}
                  queryListingsInProgress={queryWatchlistListingsInProgress}
                />
              </div>
            </li>
            <li className={css.section}>
              <div className={css.sectionContent}>
                <SectionListingVideos
                  viewport={viewport}
                  videos={listingVideos}
                  queryListingVideosInProgress={queryListingVideosInProgress}
                  queryListingVideosError={queryListingVideosError}
                />
              </div>
            </li>
          </ul>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <SectionRecentlyViewedListings
            intl={intl}
            recentlyViewedListings={recentlyViewedListings}
            queryRecentlyViewedListingInProgress={queryRecentlyViewedListingInProgress}
            queryRecentlyViewedListingsError={queryRecentlyViewedListingsError}
          />
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

LandingPageComponent.defaultProps = {
  currentUser: null,

  queryListingsInProgress: false,
  queryListingsError: null,

  queryExperiencesInProgress: false,
  queryExperiencesError: null,

  queryWatchlistListingInProgress: false,
  queryWatchlistListingsError: null,

  queryListingVideosInProgress: false,
  queryListingVideosError: null,

  queryRecentlyViewedListingInProgress: false,
  queryRecentlyViewedListingsError: null,
};

LandingPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  currentUser: propTypes.currentUser,
  onManageDisableScrolling: func.isRequired,

  listings: arrayOf(propTypes.listing),
  queryListingsInProgress: bool.isRequired,
  queryListingsError: propTypes.error,

  experiences: arrayOf(propTypes.listing),
  queryExperiencesInProgress: bool.isRequired,
  queryExperiencesError: propTypes.error,

  watchlistListings: arrayOf(propTypes.listing),
  queryWatchlistListingsInProgress: bool.isRequired,
  queryWatchlistListingsError: propTypes.error,

  listingVideos: arrayOf(propTypes.video),
  queryListingVideosInProgress: bool.isRequired,
  queryListingVideosError: propTypes.error,

  recentlyViewedListings: arrayOf(propTypes.listing),
  queryListingsInProgress: bool.isRequired,
  queryRecentlyViewedListingsError: propTypes.error,

  // from withRouter
  history: object.isRequired,
  location: object.isRequired,

  // form withViewport
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { currentUser } = state.user;
  const {
    listingIds,
    queryListingsInProgress,
    queryListingsError,
    experienceIds,
    queryExperiencesInProgress,
    queryExperiencesError,
    watchlistListingIds,
    queryWatchlistListingsInProgress,
    queryWatchlistListingsError,
    listingVideos,
    queryListingVideosInProgress,
    queryListingVideosError,
  } = state.LandingPage;
  const {
    recentlyViewedListingIds,
    queryRecentlyViewedListingInProgress,
    queryRecentlyViewedListingsError,
  } = state.user;

  const listings = getListingsById(state, listingIds);
  const experiences = getListingsById(state, experienceIds);
  const watchlistListings = getListingsById(state, watchlistListingIds);
  const recentlyViewedListings = getListingsById(state, recentlyViewedListingIds);

  return {
    scrollingDisabled: isScrollingDisabled(state),
    currentUser,
    listings,
    experiences,
    queryListingsInProgress,
    queryListingsError,
    queryExperiencesInProgress,
    queryExperiencesError,
    watchlistListings,
    queryWatchlistListingsInProgress,
    queryWatchlistListingsError,
    listingVideos,
    queryListingVideosInProgress,
    queryListingVideosError,
    recentlyViewedListings,
    queryRecentlyViewedListingInProgress,
    queryRecentlyViewedListingsError,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const LandingPage = compose(
  withRouter,
  withViewport,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(LandingPageComponent);

export default LandingPage;
